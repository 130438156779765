import React from "react";
import serviceS1 from "@/images/marketing.jpg";
import serviceS2 from "@/images/planingg.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" />
      <h2>Digitales Marketing</h2>
      <p>
        Bei Soltriks können wir Unternehmen erleichtern, um in der zu gedeihen
        Digitaler Bereich.Unsere Fachkräfte für digitale Marketing verwenden
        datengetriebene Strategien, innovative Inhalte und fortschrittliche
        Technologie zur Förderung Wachstum, erhöhen Sie die Sichtbarkeit der
        Marken und engagieren Sie Ihre Zielgruppe.Wir behaupten, dass ein
        erfolgreiches digitales Marketing nur das Publikum überschreitet
        erreichen;Es geht darum, aussagekräftige Verbindungen zu knüpfen, die um
        eine Maßnahme handeln. Ob durch Suchmaschinenoptimierung (SEO), soziale
        Medien Marketing- oder E -Mail -Kampagnen passen unseren Ansatz an, um
        sich an Ihre zu übereinstimmen Spezifische Geschäftsziele und -ziele.
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>Planung und Strategie</h3>
          <p>Dienstleistungen, die wir anbieten:</p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Suchmaschinenoptimierung
              (SEO)
            </li>
            <li>
              <i className="fa fa-check-square"></i>Pay-per-Click (PPC) Werbung
            </li>
            <li>
              <i className="fa fa-check-square"></i>Pay-Per-Click (PPC)
              Advertising
            </li>
            <li>
              <i className="fa fa-check-square"></i>Inhaltsvermarktung &
              Analytik und Berichterstattung
            </li>
            <li>
              <i className="fa fa-check-square"></i>eMailMarketing
            </li>
          </ul>
        </div>
      </div>
      <p>
        Egal, ob Sie ein Startup sind, um eine starke Markenidentität
        festzulegen oder ein etabliertes Unternehmen, das darauf abzielt, Ihre
        visuelle Präsenz zu aktualisieren, Soltriks ist hier, um Ihnen zu
        helfen.Kontaktieren Sie uns noch heute, um Ihr Design zu besprechen
        Bedürfnisse und tun Sie den ersten Schritt, um Ihre Marke in a zu
        verwandeln visuelles Meisterwerk.
      </p>
      <p>
        Bei Soltriks erkennen wir an, dass die Grundlage eines erfolgreichen
        Webs Entwicklungsprojekt liegt in der akribischen Planung.Vor dem
        Schreiben einer Single Codelinie oder Entwerfen eines einzelnen Pixels
        investieren wir Zeit in die Zeit Verstehen Sie Ihre Geschäftsziele,
        Zielgruppen und einzigartig Anforderungen.Unser umfassender
        Planungsprozess sorgt dafür Aspekt Ihres Projekts wird sorgfältig
        berücksichtigt und mit ausgeführt Präzision.
      </p>
    </div>
  );
};

export default ServiceDetailsContent;
